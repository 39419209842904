import React from "react";
import tw from "twin.macro";
import styled from "@emotion/styled";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import Testimonials from "../components/Repeating/Testimonials";
import WhyUs from "../components/Repeating/WhyUs";
// import RecentPosts from "../components/Repeating/RecentPosts";
import HarborViewLifestyle from "../components/Repeating/HarborViewLifestyle";
import Services from "../components/Repeating/Services";
import CallToAction from "../components/Repeating/CTA";
// import ButtonGhost from "../components/Button/ButtonGhost";
import ButtonGhostMobile from "../components/Button/ButtonGhostMobile";
import ButtonSolid from "../components/Button/ButtonSolid";
const StyledContent = styled.div`
  p {
    ${tw`before:absolute before:bg-tertiary-400 before:block before:bottom-[10px] before:h-[16px] before:leading-[75px] before:right-[-16px] before:rounded-full before:w-[16px] inline-block relative rounded-full`}
  }
`;

const IndexPage = ({ data }) => {
  return (
    <Layout siteSettings={data.siteSettings} contactInfo={data.contactInfo}>
      <SearchEngineOptimization
        title="BayView Senior Assisted Living | Point Loma, CA"
        description="BayView Senior Assisted Living is a memory care community in Point Loma that's dedicated to providing gentle, individualized care. Call to schedule a tour."
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      {/* START OF HERO */}
      <section className="relative bg-primary-500 md:h-[350px] md:bg-transparent lg:h-[450px] xl:h-[650px] ">
        {/* START OF DESKTOP UNDERLAY */}
        <div className=" absolute -bottom-[70px] left-0 mt-[50px] hidden w-full md:mt-[60px] md:block  lg:bottom-0">
          <StaticImage
            src="../images/1.0 Homepage/1.0 hero-desktop.png"
            loading="eager"
            placeholder="none"
            objectPosition="bottom"
          />
        </div>
        {/* END OF DESKTOP UNDERLAY */}
        {/* START OF HERO CONTENT */}
        <div className="relative flex h-full flex-col justify-center pt-0 md:pt-[inherit] ">
          <div>
            {/* START OF DESKTOP */}
            <div className="hidden lg:block">
              <div className="container">
                <StyledContent>
                  <p className="font-heading text-[54px] font-semibold leading-[60px] text-white md:text-[64px] md:leading-[70px] ">
                    Just like home
                  </p>
                </StyledContent>
                <p className="max-w-[640px] font-body text-[22px] font-normal leading-[32px] text-white ">
                  Compassionate memory care and assisted living in a beautiful
                  boutique home near the bay in Point Loma.
                </p>
                <ButtonSolid
                  modal="modal-contact"
                  text="Contact Us"
                  className="mx-auto max-w-[180px] text-[16px] font-normal lg:ml-0"
                />
              </div>
            </div>
            {/* END OF DESKTOP */}
            {/* START OF TABLET */}
            <div className="hidden  md:block lg:hidden">
              <div className="container">
                <StyledContent>
                  <p className="font-heading text-[54px] font-semibold leading-[60px] text-white md:text-[64px] md:leading-[70px] ">
                    Just like home
                  </p>
                </StyledContent>
                <p className="max-w-[425px] text-white">
                  Compassionate memory care and assisted living in a beautiful
                  boutique home near the bay in Point Loma.
                </p>
                <ButtonSolid modal="modal-contact" text="Contact Us" />
              </div>
            </div>
            {/* END OF TABLET */}
            {/* START OF MOBILE */}
            <div className="block  md:hidden">
              <div className="container my-[56px] py-4 text-center md:my-auto">
                <p className="font-heading text-[40px] leading-[50px] text-white">
                  Where they’ll feel<br></br>right at home.
                </p>
                <p className="text-[20px] font-normal leading-[34px] text-white">
                  Compassionate memory care and assisted living in a beautiful
                  boutique home near the bay in Point Loma.
                </p>
                <ButtonGhostMobile
                  modal="modal-contact"
                  text="Contact Us"
                  altStyle={2}
                />
              </div>
              <div className="block">
                <StaticImage
                  src="../images/1.0 Homepage/1.0 hero-mobile.jpg"
                  loading="eager"
                  placeholder="none"
                  className="h-full w-full"
                  imgClassName="object-top"
                />
              </div>
            </div>
            {/* END OF MOBILE */}
          </div>
        </div>
        {/* END OF HERO CONTENT  */}
      </section>
      {/* END OF HERO */}

      {/* START OF PAGE CONTENT */}
      <section className="mb-20 pt-[45px] md:mb-32 md:pt-[100px] lg:pt-24 xl:pt-[7.5rem]">
        <div className="container">
          <div className="grid items-center gap-y-10 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div>
              <h1 className="text-5xl ">
                Top Alzheimer’s & Dementia Care Serving Point Loma & Greater San
                Diego, CA
              </h1>
              <p>
                BayView Senior Assisted Living is a memory care community
                located in the coastal community of Point Loma. We’re dedicated
                to providing gentle, individualized care and support services
                for residents living with Alzheimer’s disease or other types of
                dementia. Our home is a caring place where your loved ones can
                age in place gracefully and with dignity.
              </p>
            </div>
            <div>
              <StaticImage
                src="../images/1.0 Homepage/2.0 intro.png"
                alt="Top Alzheimer’s & Dementia Care Serving Encinitas & North County, CA"
                width={560}
                loading="eager"
                placeholder="none"
              />
            </div>
          </div>
        </div>
      </section>
      {/* END OF PAGE CONTENT */}
      {/* START OF PAGE COMPONENTS */}
      <Services />
      <HarborViewLifestyle
        shadow={true}
        className="mb-14  bg-secondary-400 p-4 md:mb-24 lg:p-24 "
        hide={4}
      />
      <WhyUs />
      <Testimonials />
      {/* <RecentPosts /> */}
      <CallToAction phonenumber={data.contactInfo.phonenumber} />
      {/* END OF PAGE COMPONENTS */}
    </Layout>
  );
};

export const data = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "open-graph/facebook/Facebook.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "open-graph/twitter/Twitter.jpg" }
    ) {
      publicURL
    }
    sanitySiteSettings {
      title
    }

    allSanityServices {
      nodes {
        title
        _id
      }
      totalCount
    }
    contactInfo: sanityContactinfo {
      title
      description
      phonenumber
      email
      address
      license
      bookingurl
    }
    siteSettings: sanitySiteSettings {
      title
      description
      author
      headerlogo {
        asset {
          gatsbyImageData
        }
      }
      footerlogo {
        asset {
          gatsbyImageData
        }
      }
      facebookurl
      twitterurl
      privacypolicy
    }
  }
`;

export default IndexPage;
